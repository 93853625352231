import {
  queryMapToCriteria,
  TMediaMeta,
  TMediaMetaMap,
  TOrientation,
} from '@hotelplan/core.basis.bs-picture';
import { ImageResizingType } from '@hotelplan/platform-graphql-api';
import {
  l1024x768,
  l1368x912,
  l1920,
  l2560,
  l3440,
  l3840,
  l740x375,
  l915x414,
  p375x740,
  p414x915,
  p768x1024,
  p912x1368,
} from 'components/domain/criterias/base.dmc';

const mediaMobileSpecs: Array<[string, TMediaMeta<ImageResizingType>]> = [
  [`375w_mobile`, { ...p375x740, resizingType: ImageResizingType.FillDown }],
  [`414w_mobile`, { ...p414x915, resizingType: ImageResizingType.FillDown }],
  [`768w_mobile`, { ...p768x1024, resizingType: ImageResizingType.FillDown }],
  [`912w_mobile`, { ...p912x1368, resizingType: ImageResizingType.FillDown }],

  // mobile landscape
  [`740w_mobile`, { ...l740x375, resizingType: ImageResizingType.FillDown }],
  [`915w_mobile`, { ...l915x414, resizingType: ImageResizingType.FillDown }],
  [`1024w_mobile`, { ...l1024x768, resizingType: ImageResizingType.FillDown }],
  [`1368w_mobile`, { ...l1368x912, resizingType: ImageResizingType.FillDown }],
];

const mediaMobileFullSizeSpecs: Array<
  [string, TMediaMeta<ImageResizingType>]
> = [
  [`375w_mobile`, { ...p375x740, resizingType: ImageResizingType.Fit }],
  [`414w_mobile`, { ...p414x915, resizingType: ImageResizingType.Fit }],
  [`768w_mobile`, { ...p768x1024, resizingType: ImageResizingType.Fit }],
  [`912w_mobile`, { ...p912x1368, resizingType: ImageResizingType.Fit }],

  // mobile landscape
  [`740w_mobile`, { ...l740x375, resizingType: ImageResizingType.Fit }],
  [`915w_mobile`, { ...l915x414, resizingType: ImageResizingType.Fit }],
  [`1024w_mobile`, { ...l1024x768, resizingType: ImageResizingType.Fit }],
  [`1368w_mobile`, { ...l1368x912, resizingType: ImageResizingType.Fit }],
];

const mediaDesktopFullSizeSpecs: Array<
  [string, TMediaMeta<ImageResizingType>]
> = [
  [`1920w_desktop`, { ...l1920(1080), resizingType: ImageResizingType.Fit }],
  [`2560w_desktop`, { ...l2560(1440), resizingType: ImageResizingType.Fit }],
  [`3440w_desktop`, { ...l3440(1440), resizingType: ImageResizingType.Fit }],
  [`3840w_desktop`, { ...l3840(2160), resizingType: ImageResizingType.Fit }],
];

export const mediaFullSpecs: TMediaMetaMap<ImageResizingType> = new Map([
  ...mediaMobileFullSizeSpecs,
  ...mediaDesktopFullSizeSpecs,
]);

export const mediaPreviewSpecs: TMediaMetaMap<ImageResizingType> = new Map([
  ...mediaMobileSpecs,
  [
    `1440w_desktop`,
    {
      s: { width: 1440, height: 640 },
      resizingType: ImageResizingType.FillDown,
      q: { minW: 1368.1 },
      orientation: TOrientation.landscape,
    },
  ],
]);

export const mediaPreview = queryMapToCriteria(mediaPreviewSpecs);
export const mediaFull = queryMapToCriteria(mediaFullSpecs);
