import {
  queryMapToCriteria,
  TMediaMetaMap,
  TMediaMeta,
} from '@hotelplan/core.basis.bs-picture';
import { ImageResizingType } from '@hotelplan/platform-graphql-api';
import {
  l1024,
  l1368,
  l1920,
  l2560,
  l3440,
  l3840,
  l740,
  l915,
  p375,
  p414,
  p768,
  p912,
} from 'components/domain/criterias/base.dmc';

export const marketingMobileImagesSpecs: Array<
  [string, TMediaMeta<ImageResizingType>]
> = [
  [
    `375w_mobile`,
    {
      ...p375(263),
      resizingType: ImageResizingType.FillDown,
    },
  ],
  [
    `414w_mobile`,
    {
      ...p414(290),
      resizingType: ImageResizingType.FillDown,
    },
  ],
  [
    `768w_mobile`,
    {
      ...p768(538),
      resizingType: ImageResizingType.FillDown,
    },
  ],
  [
    `912w_mobile`,
    {
      ...p912(347),
      resizingType: ImageResizingType.FillDown,
    },
  ],

  // mobile landscape
  [
    `740w_mobile`,
    {
      ...l740(518),
      resizingType: ImageResizingType.FillDown,
    },
  ],
  [
    `915w_mobile`,
    {
      ...l915(348),
      resizingType: ImageResizingType.FillDown,
    },
  ],
  [
    `1024w_mobile`,
    {
      ...l1024(390),
      resizingType: ImageResizingType.FillDown,
    },
  ],
  [
    `1368w_mobile`,
    {
      ...l1368(520),
      resizingType: ImageResizingType.FillDown,
    },
  ],
];

const marketingDesktopImagesSpecs: Array<
  [string, TMediaMeta<ImageResizingType>]
> = [
  [
    `1920w_desktop`,
    {
      ...l1920(724),
      resizingType: ImageResizingType.FillDown,
    },
  ],
  [
    `2560w_desktop`,
    {
      ...l2560(967),
      resizingType: ImageResizingType.FillDown,
    },
  ],
  [
    `3440w_desktop`,
    {
      ...l3440(1052),
      resizingType: ImageResizingType.FillDown,
    },
  ],
  [
    `3840w_desktop`,
    {
      ...l3840(1052),
      resizingType: ImageResizingType.FillDown,
    },
  ],
];

export const marketingImagesSpecs: TMediaMetaMap<ImageResizingType> = new Map([
  ...marketingMobileImagesSpecs,
  ...marketingDesktopImagesSpecs,
]);

export const marketingRecommendation = queryMapToCriteria(marketingImagesSpecs);
