import { mapCoordinatesToLatLngLiteral } from '@hotelplan/components.common.map-pin';
import {
  MapClickEventTargetType,
  trackMapClick,
} from 'components/domain/tracking/pdpTracking';
import { ClickEventActionType } from 'components/domain/tracking/tracking.types';
import { RoutePointFragment } from 'graphql/fragments/ProductGalleryWithMap.generated';

export const getPath = (routePoints: RoutePointFragment[]) => {
  return routePoints.map(point => {
    return mapCoordinatesToLatLngLiteral(point.coordinates);
  });
};

export const nearbyCheckbox = ({ onChange, text, showNearBy, isPdp }) => () => {
  const label = document.createElement('label');

  const span = document.createElement('span');
  span.setAttribute(`class`, `customCheckboxInput`);

  const checkbox = document.createElement('input');
  checkbox.type = 'checkbox';
  checkbox.checked = !!showNearBy;
  checkbox.onchange = () => {
    onChange(checkbox.checked);

    if (!checkbox.checked || !isPdp) return;
    trackMapClick(
      ClickEventActionType.SELECT,
      MapClickEventTargetType.ROUTES_NEARBY
    );
  };

  label.appendChild(checkbox);
  label.append(`${text}`);
  label.appendChild(span);

  const container = document.createElement('div');
  container.setAttribute(
    `class`,
    `map-nearby-container map-nearby-input-wrapper`
  );
  container.appendChild(label);

  return container;
};
