import React from 'react';
import { FlaglineText, H4Heading } from 'theme/headings';
import { INormalizedImage } from '@hotelplan/components.common.image';
import { PlaceholderLoader } from '@hotelplan/components.common.placeholder-loader';
import ImgWithCopyright from 'components/domain/copyright/img-with-copyright';
import CopyShare from 'components/domain/share-overlay/CopyShare';
import Print from 'components/domain/share-overlay/Print';
import ShareButton from 'components/domain/share-overlay/ShareButton';
import { TBookmarkInfo } from 'components/domain/sitetools/use-pdf-url';
import {
  ImageWrap,
  ModalCloseButton,
  SearchDetailsContent,
  ShareOverlayWrap,
  ShareSocialNetworksList,
} from './share-overlay.styles';
import { EShareType, useShareLinks } from './useShareLinks';

interface IShareOverlayProps {
  onClose?(): void;
  image: INormalizedImage;
  pageTitle: string;
  overlayTitle: string;
  toShare: string | TBookmarkInfo[];
  whiteList?: Array<EShareType>;
  trackFn(target: string): void;
  pdf?: boolean;
  loading?: boolean;
}

const iconPlaceholder = (
  <PlaceholderLoader
    width={42}
    height={42}
    speed={2}
    style={{ width: `42px`, height: '42px' }}
  >
    <rect width={42} height={42} rx={8} />
  </PlaceholderLoader>
);

const ShareOverlay: React.FC<IShareOverlayProps> = ({
  onClose,
  image,
  pageTitle,
  overlayTitle,
  toShare,
  whiteList,
  trackFn,
  pdf,
  loading,
}) => {
  const linksShare = useShareLinks(pageTitle, toShare, whiteList);

  const trackCopyOrShare = (target: string) => {
    trackFn(target);
  };

  return (
    <ShareOverlayWrap className="share-overlay">
      <FlaglineText className="tagline-text">{overlayTitle}</FlaglineText>
      <SearchDetailsContent>
        <ImageWrap>
          {image ? <ImgWithCopyright {...image} className="image" /> : null}
        </ImageWrap>
        <H4Heading className="heading">{pageTitle}</H4Heading>
      </SearchDetailsContent>
      <ShareSocialNetworksList>
        {loading && (
          <div style={{ columnGap: '16px', display: 'flex' }}>
            {Array.from({ length: whiteList.length }, (_, i) => (
              <React.Fragment key={i}>{iconPlaceholder}</React.Fragment>
            ))}
          </div>
        )}
        {!loading &&
          Object.keys(linksShare).map(platform => {
            if (!linksShare[platform]) return;

            if (platform === 'print')
              return <Print key={platform} icon={linksShare[platform].icon} />;

            return (
              <ShareButton
                key={platform}
                item={linksShare[platform]}
                trackFn={() => {
                  // @todo: tracking for char number will go here;
                  trackFn(platform);
                }}
                platform={platform}
              />
            );
          })}
      </ShareSocialNetworksList>
      {!Array.isArray(toShare) && (
        <CopyShare toShare={toShare} trackFn={trackCopyOrShare} pdf={pdf} />
      )}
      <ModalCloseButton onClick={onClose}>{`Close window`}</ModalCloseButton>
    </ShareOverlayWrap>
  );
};

export default ShareOverlay;
