import { useRouter } from 'next/router';
import { useAuthContext } from '@hotelplan/libs.auth-next';
import { AuthChannelType } from 'graphql/types/resolversTypes';

export const useShareUrl = () => {
  const { asPath } = useRouter();
  const {
    user: { agency, channel },
  } = useAuthContext();

  const documentLocationOrigin =
    typeof document !== 'undefined' ? document.location.origin : '';

  const agencyUrlQuery =
    agency && channel === AuthChannelType.B2B ? `?agency=${agency}` : '';

  return `${documentLocationOrigin}${asPath}${agencyUrlQuery}`;
};
