import {
  queryMapToCriteria,
  TMediaMetaMap,
} from '@hotelplan/core.basis.bs-picture';
import { ImageResizingType } from '@hotelplan/platform-graphql-api';

export const blogSpecs: TMediaMetaMap<ImageResizingType> = new Map([
  [`1040w_desktop`, { s: { width: 497, height: 590 }, q: { maxW: 1240 } }],
  [
    `1240w_desktop`,
    { s: { width: 695, height: 590 }, q: { minW: 1240.1, maxW: 1495 } },
  ],
  [`1495w_desktop`, { s: { width: 952, height: 640 }, q: { minW: 1495.1 } }],
]);

export const blogCriteria = queryMapToCriteria(blogSpecs);
