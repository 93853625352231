import { useTranslation } from 'next-i18next';
import React from 'react';
import { BsCopyright } from '@hotelplan/core.basis.bs-copyright';
import { BsImg, IImageProps } from '@hotelplan/core.basis.bs-img';

const ImgWithCopyright: React.FC<IImageProps> = ({ copyright, ...image }) => {
  const [t] = useTranslation(['common']);

  return (
    <BsImg {...image}>
      {copyright && (
        <BsCopyright>
          {t('copyright.photo')} {copyright}
        </BsCopyright>
      )}
    </BsImg>
  );
};

export default ImgWithCopyright;
