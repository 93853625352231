import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { useGetLinkWithJotform } from 'components/domain/jotform/jotformGclid.utils';
import RequestButton, {
  IRequestButtonProps,
} from 'components/domain/request-button/RequestButton';

const StaticRequestButtonBg = styled.div<{ bg?: string }>(
  ({ bg, theme: { colors } }) =>
    sx2CssThemeFn({
      backgroundColor: bg || colors.paperGrey,
      flexGrow: ['1', `unset`],
    })
);

const StaticRequestButtonWrapper = styled.div(
  ({ theme }) => theme.text.textBlockWrap
);

export default function StaticRequestButton(
  props: IRequestButtonProps & {
    className?: string;
    backgroundColor?: string;
  }
): ReactElement {
  const { backgroundColor, className, onClick, link } = props;
  const linkWithJotform = useGetLinkWithJotform(link);

  return (
    <StaticRequestButtonBg bg={backgroundColor}>
      <StaticRequestButtonWrapper
        className={`static-request-button-wrapper ${className}`}
      >
        <RequestButton {...props} onClick={onClick} link={linkWithJotform} />
      </StaticRequestButtonWrapper>
    </StaticRequestButtonBg>
  );
}
