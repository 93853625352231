import {
  queryMapToCriteria,
  TMediaMetaMap,
} from '@hotelplan/core.basis.bs-picture';
import { ImageResizingType } from '@hotelplan/platform-graphql-api';
import { mediaFullSpecs } from 'components/domain/criterias/media.dmc';

export const galleryWithMapPreviewSpecs: TMediaMetaMap<ImageResizingType> = new Map(
  [
    [`374w_mobile`, { s: { width: 327, height: 281 }, q: { maxW: 375 } }],
    [
      `375w_mobile`,
      { s: { width: 550, height: 473 }, q: { minW: 375.1, maxW: 600 } },
    ],
    [
      `600w_mobile`,
      { s: { width: 846, height: 727 }, q: { minW: 600.1, maxW: 895 } },
    ],
    [
      `895w_desktop`,
      { s: { width: 413, height: 355 }, q: { minW: 895.1, maxW: 1100 } },
    ],
    [
      `1100w_desktop`,
      { s: { width: 511, height: 439 }, q: { minW: 1100.1, maxW: 1495 } },
    ],
    [`14950w_desktop`, { s: { width: 710, height: 610 }, q: { minW: 1495.1 } }],
  ]
);

export const galleryWithMapFullSpecs = mediaFullSpecs;

export const galleryWithMapPreview = queryMapToCriteria(
  galleryWithMapPreviewSpecs
);
export const galleryWithMapFull = queryMapToCriteria(galleryWithMapFullSpecs);
