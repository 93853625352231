import React from 'react';
import { ShareFormWrap } from 'components/domain/share-overlay/share-overlay.styles';

type TProps = {
  toShare: string;
  trackFn(target: string): void;
  pdf?: boolean;
};

const CopyShare: React.FC<TProps> = ({ toShare, trackFn, pdf }: TProps) => {
  return <ShareFormWrap url={toShare} onCopyButtonClick={trackFn} pdf={pdf} />;
};

export default CopyShare;
