import Head from 'next/head';
import React from 'react';
import { Image } from '@hotelplan/platform-graphql-api';

type TProps = {
  image: Image;
};

const HeroOgTags: React.FC<TProps> = ({ image }: TProps) => {
  const alt = image?.alt;
  const url = image?.resized[0]?.url;
  {
    /*<meta key="og:type" property="og:type" content={type} />*/
  }

  return (
    <Head>
      {url && <meta key="og:image" property="og:image" content={url} />}
      {alt && <meta key="og:image:alt" property="og:image:alt" content={alt} />}
    </Head>
  );
};

export default HeroOgTags;
