import { useTranslation } from 'next-i18next';
import React from 'react';
import { BsCopyright } from '@hotelplan/core.basis.bs-copyright';
import {
  BsRsvImg,
  TResponsiveImageProps,
} from '@hotelplan/core.fusion.bs-rsv-img';
import { ImageResizingType } from '@hotelplan/platform-graphql-api';

const ResponsiveImgWithCopyright: React.FC<
  TResponsiveImageProps<ImageResizingType>
> = ({ image: { copyright, ...img } = {}, ...rest }) => {
  const [t] = useTranslation(['common']);

  return (
    <BsRsvImg image={img} {...rest}>
      {copyright && (
        <BsCopyright>
          {t('copyright.photo')} {copyright}
        </BsCopyright>
      )}
    </BsRsvImg>
  );
};

export default ResponsiveImgWithCopyright;
