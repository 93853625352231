import { TOrientation } from '@hotelplan/core.basis.bs-picture';

export const p375 = height => ({
  s: { width: 375, height: height },
  q: { maxW: 375 },
  orientation: TOrientation.portrait,
});

export const p375x740 = p375(740);

export const p414 = height => ({
  s: { width: 414, height: height },
  q: { maxW: 414, minW: 375.1 },
  orientation: TOrientation.portrait,
});

export const p414x915 = p414(915);

export const p768 = height => ({
  s: { width: 768, height: height },
  q: { maxW: 768, minW: 414.1 },
  orientation: TOrientation.portrait,
});

export const p768x1024 = p768(1024);

export const p912 = height => ({
  s: { width: 912, height: height },
  q: { minW: 768.1 },
  orientation: TOrientation.portrait,
});

export const p912x1368 = p912(1368);

export const l740 = height => ({
  s: { width: 740, height: height },
  q: { maxW: 740 },
  orientation: TOrientation.landscape,
});

export const l740x375 = l740(375);

export const l915 = height => ({
  s: { width: 915, height: height },
  q: { maxW: 915, minW: 740.1 },
  orientation: TOrientation.landscape,
});

export const l915x414 = l915(414);

export const l1024 = height => ({
  s: { width: 1024, height: height },
  q: { maxW: 1024, minW: 915.1 },
  orientation: TOrientation.landscape,
});

export const l1024x768 = l1024(768);

export const l1368 = height => ({
  s: { width: 1368, height },
  q: { maxW: 1368, minW: 1024.1 },
  orientation: TOrientation.landscape,
});

export const l1368x912 = l1368(912);

export const l1920 = height => ({
  s: { width: 1920, height },
  q: { maxW: 1920, minW: 1368.1 },
  orientation: TOrientation.landscape,
});

export const l2560 = height => ({
  s: { width: 2560, height },
  q: { maxW: 2560, minW: 1920.1 },
  orientation: TOrientation.landscape,
});

export const l3440 = height => ({
  s: { width: 3440, height },
  q: { maxW: 3440, minW: 2560.1 },
  orientation: TOrientation.landscape,
});

export const l3840 = height => ({
  s: { width: 3840, height },
  q: { minW: 3440.1 },
  orientation: TOrientation.landscape,
});
