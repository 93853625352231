import { CSSObject } from 'styled-components';

export const pinContent: CSSObject = {
  '.map-pin-content': {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    padding: 0,
    bottom: '-6px',
    color: '#E4251B',
  },
  '.map-pin-wrapper:after': {
    borderTopColor: 'transparent',
  },
};
