import {
  mediaFullSpecs,
  mediaPreviewSpecs,
} from 'components/domain/criterias/media.dmc';
import {
  galleryWithMapFullSpecs,
  galleryWithMapPreviewSpecs,
} from 'components/domain/criterias/productGallery.dmc';
import { renderMediaItemPartial } from 'components/domain/default-media-item/default-media-item-render.utils';

export * from 'components/domain/criterias/hero.dmc';
export * from 'components/domain/criterias/media.dmc';
export * from 'components/domain/criterias/productGallery.dmc';
export * from 'components/domain/criterias/blog.dmc';
export * from 'components/domain/criterias/continet.dmc';
export * from 'components/domain/criterias/marketingRecommendation.dmc';

export const renderGalleryWithMap = renderMediaItemPartial(
  galleryWithMapPreviewSpecs,
  galleryWithMapFullSpecs
);

export const renderMedia = renderMediaItemPartial(
  mediaPreviewSpecs,
  mediaFullSpecs
);
