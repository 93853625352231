import copy from 'copy-to-clipboard';
import { useTranslation } from 'next-i18next';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Button } from '@hotelplan/components.common.buttons';
import { TextInput } from '@hotelplan/components.common.text-input';
import {
  IMetaDataContext,
  useMetaDataContext,
} from '@hotelplan/libs.meta-data';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import ShareIcon from 'components/domain/sitetools/ShareIcon';

const ShareFormWrap = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    mb: [2, 0],
    '.input-wrap': {
      width: '100%',
      mr: [3, '20px'],
    },
    '.input': {
      backgroundColor: 'bgLightBlue',
    },
  })
);

const CopyButtonWrap = styled.div({
  position: 'relative',
  '.tooltip': {
    display: 'none',
  },
  ':hover': {
    '.tooltip': {
      display: 'block',
    },
  },
});

const CopyTooltip = styled.span.attrs({
  className: 'tooltip',
})(({ theme: { colors, fontSizes, space } }) => ({
  display: 'block',
  position: 'absolute',
  width: 'auto',
  color: 'white',
  fontWeight: '400',
  fontSize: fontSizes[1],
  lineHeight: '22px',
  letterSpacing: '0.5px',
  backgroundColor: colors.darkGrey,
  boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.1)',
  borderRadius: '5px',
  padding: '5px 10px',
  left: '50%',
  transform: 'translateX(-50%)',
  marginTop: space[1],
  whiteSpace: 'nowrap',
  zIndex: '110',
  '&:before': {
    content: '""',
    position: 'absolute',
    width: '16px',
    height: '16px',
    left: '50%',
    top: '-8px',
    backgroundColor: colors.darkGrey,
    transform: 'translateX(-50%)  rotate(-45deg)',
    zIndex: '1',
  },
}));

const CommonButton = styled(Button).attrs({
  variant: 'iconBtn',
})(
  sx2CssThemeFn({
    mt: 4,
    alignSelf: 'center',
    '.icon': {
      variant: ['icons.lg', 'icons.xlg'],
      color: 'black',
    },
  })
);

const CopyButton = styled(Button).attrs({
  icon: {
    name: 'copy',
  },
  variant: 'iconBtn',
})(
  sx2CssThemeFn({
    mt: 4,
    alignSelf: 'center',
    '.icon': {
      variant: ['icons.lg', 'icons.xlg'],
    },
  })
);

const TIMEOUT_TO_SHOW_COPIED = 3000;

enum CopyState {
  DEFAULT,
  SUCCESS,
  ERROR,
}

export function share(meta: IMetaDataContext, url: string) {
  const windowNavigator = window.navigator as any;

  if (windowNavigator?.share && meta) {
    windowNavigator.share({
      title: meta.title,
      text: meta.description,
      url: url || window.location.href,
    });
  }
}

const ShareForm: React.FC<{
  className?: string;
  onCopyButtonClick?: (type: string) => void;
  url: string;
  pdf?: boolean;
}> = ({ className, onCopyButtonClick, url, pdf }) => {
  const meta = useMetaDataContext();
  const isShareAvailable =
    pdf && typeof window !== 'undefined' && window?.navigator?.share;

  const [t] = useTranslation(['forms', 'common']);
  const [copyState, setCopyState] = useState<CopyState>(CopyState.DEFAULT);
  const shareUrl = url || window.location.href;

  const copyTooltipText = useMemo((): string => {
    switch (copyState) {
      case CopyState.SUCCESS:
        return t('forms:copied.button');
      case CopyState.ERROR:
        return t('forms:copiedWithError.button');
      default:
        return t('forms:copy.button');
    }
  }, [copyState, t]);

  const handleCopyButtonClick = (): void => {
    onCopyButtonClick && onCopyButtonClick(`copy`);
    const isSuccess = copy(shareUrl, { format: 'text/plain' });
    setCopyState(isSuccess ? CopyState.SUCCESS : CopyState.ERROR);
  };

  useEffect(
    function resetCopyStateOnTimeout() {
      const timerRef = { current: null };
      if (!timerRef.current && copyState === CopyState.SUCCESS) {
        timerRef.current = setTimeout(() => {
          // prevent setState on unmounted component
          if (!timerRef.current) return;
          setCopyState(CopyState.DEFAULT);
        }, TIMEOUT_TO_SHOW_COPIED);

        return (): void => {
          clearTimeout(timerRef.current);
          timerRef.current = null;
        };
      }
    },
    [copyState]
  );

  return (
    <ShareFormWrap className={className}>
      <div className="input-wrap">
        <TextInput
          label={t('common:share.label')}
          testId={'sharing-url-id'}
          value={shareUrl}
          onChange={() => void 0}
          required
          readOnly
        />
      </div>
      <CopyButtonWrap>
        {isShareAvailable && (
          <CommonButton
            onClick={() => {
              share(meta, url);
            }}
          >
            <ShareIcon />
          </CommonButton>
        )}
        {!isShareAvailable && (
          <>
            <CopyButton
              onClick={handleCopyButtonClick}
              aria-label={t('forms:copy.button')}
            />
            <CopyTooltip>{copyTooltipText}</CopyTooltip>
          </>
        )}
      </CopyButtonWrap>
    </ShareFormWrap>
  );
};

export default ShareForm;
