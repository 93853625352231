import { PropsWithChildren, ReactElement, useEffect } from 'react';
import styled from 'styled-components';
import { colors } from 'theme/index';
import { Link } from '@hotelplan/components.common.link';
import RouterLink from '@hotelplan/components.common.router-link';
import { ExternalLink } from '@hotelplan/graphql.types';
import {
  getOpenMethod,
  getRouterLink,
} from '@hotelplan/libs.router-link-utils';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { externalLinkSubject } from 'components/domain/external-overlay';

export interface IRequestButtonProps {
  backgroundColor?: string;
  caption: string;
  link: ExternalLink;
  onClick?(): void;
}

const RequestButtonLink = styled(Link)(({ theme }) =>
  sx2CssThemeFn({
    ...theme.buttons.primary,
    '&.red-bg': theme.buttons.primaryOnRedBg,
  })
);

export default function RequestButton(
  props: PropsWithChildren<IRequestButtonProps>
): ReactElement {
  const { caption, link, onClick, backgroundColor } = props;
  const hasRedBackground =
    backgroundColor?.toLowerCase() === colors.redBurgundy?.toLowerCase();

  useEffect(
    function initRequestLinkLoad() {
      if (link) {
        externalLinkSubject.next(link.url);
      }
    },
    [link]
  );

  return (
    <RouterLink {...(link ? getRouterLink(link) : undefined)} passHref>
      <RequestButtonLink
        target={link ? getOpenMethod(link) : undefined}
        className={`request-button ${hasRedBackground ? 'red-bg' : ''}`}
        onClick={onClick}
      >
        <span
          className="request-button-caption"
          dangerouslySetInnerHTML={{ __html: caption }}
        />
      </RequestButtonLink>
    </RouterLink>
  );
}
