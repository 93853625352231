const MAP_SATURATION = -30;

export const commonMapOptions: google.maps.MapOptions = {
  gestureHandling: `cooperative`,
  styles: [
    {
      stylers: [{ saturation: MAP_SATURATION }],
    },
    {
      featureType: `poi`,
      stylers: [{ visibility: `off` }],
    },
    {
      featureType: `landscape.natural`,
      elementType: `labels`,
      stylers: [{ visibility: `off` }],
    },
  ],
  minZoom: 2,
  restriction: {
    latLngBounds: {
      north: 85,
      south: -85,
      west: -180,
      east: 180,
    },
  },
};

export const productMapOptions: google.maps.MapOptions = {
  ...commonMapOptions,
  mapTypeControl: false,
};
