import { pick } from 'lodash/fp';
import { useTranslation } from 'next-i18next';
import React from 'react';
import { TBookmarkInfo } from 'components/domain/sitetools/use-pdf-url';

export enum EShareType {
  FACEBOOK = 'facebook',
  X = 'x',
  WHATSAPP = 'whatsapp',
  MAIL = 'mail',
  DOWNLOAD = 'download',
}

export type TShareLink = {
  name?: string;
  icon: string;
  apiLink: string | undefined;
  func?: (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => void | undefined;
  charNum?: number;
};

type TShareLinks = {
  [key in EShareType]: TShareLink;
};

const NEW_LINE = decodeURIComponent(`%0D%0A`);
const DOUBLE_NEW_LINE = decodeURIComponent(`%0D%0A%0D%0A`);

const CHAR_LIMIT = 2047;

const getUrlsText = (
  toShare: TBookmarkInfo[],
  textSize: number
): [string, number] => {
  const fullResult = toShare
    .map(({ link, name }) => [name?.trim(), link?.trim()].join(NEW_LINE))
    .join(DOUBLE_NEW_LINE);

  const linkLength = encodeURIComponent(fullResult).length + textSize;

  if (linkLength <= CHAR_LIMIT) return [fullResult, linkLength];

  let result = '';

  for (const { link, name } of toShare) {
    const text = [name?.trim(), link?.trim()].join(NEW_LINE);
    const tmpRes = result ? result + DOUBLE_NEW_LINE + text : text;

    if (encodeURIComponent(tmpRes).length + textSize > CHAR_LIMIT)
      return [result, linkLength];

    result = tmpRes;
  }

  return [result, result.length];
};

const facebook = (
  title: string,
  toShare: string | TBookmarkInfo[]
): TShareLink | undefined => {
  if (Array.isArray(toShare)) return undefined;

  return {
    icon: `facebook-logo`,
    apiLink: `https://facebook.com/sharer.php?u=${encodeURIComponent(toShare)}`,
  };
};

const twitter = (
  title: string,
  toShare: string | TBookmarkInfo[]
): TShareLink | undefined => {
  if (Array.isArray(toShare)) return undefined;

  return {
    icon: `x-logo`,
    apiLink: `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      title
    )}&url=${encodeURIComponent(toShare)}`,
  };
};

const whatsapp = (
  title: string,
  toShare: string | TBookmarkInfo[]
): TShareLink => {
  const urlPrefix = `https://api.whatsapp.com/send/?phone&text=`;

  if (!Array.isArray(toShare)) {
    return {
      icon: `whatsapp-logo`,
      apiLink: `${urlPrefix}${encodeURIComponent(toShare)}`,
    };
  }

  const [sharedText, charNum] = getUrlsText(toShare, urlPrefix.length);
  return {
    icon: `whatsapp-logo`,
    apiLink: `${urlPrefix}${encodeURIComponent(sharedText)}`,
    charNum,
  };
};

const mail = (
  title: string,
  toShare: string | TBookmarkInfo[],
  body: (text: string) => string
): TShareLink => {
  const urlPrefix = `mailto:?subject=${encodeURIComponent(title)}&body=`;

  if (!Array.isArray(toShare)) {
    return {
      icon: `mail`,
      apiLink: `${urlPrefix}${encodeURIComponent(toShare)}`,
    };
  }

  const getLink = pdfs =>
    `${urlPrefix}${encodeURIComponent(body(title + DOUBLE_NEW_LINE + pdfs))}`;

  const [pdfs, charNum] = getUrlsText(toShare, getLink('').length);

  return {
    icon: `mail`,
    apiLink: getLink(pdfs),
    charNum,
  };
};

const downloadLink = (link: string) => `/api/pdf/download?file=${link}`;

const download = (
  title: string,
  toShare: string | TBookmarkInfo[]
): TShareLink => {
  if (Array.isArray(toShare)) {
    return {
      icon: `download`,
      apiLink: undefined,
      func(e) {
        e.preventDefault();

        toShare.forEach(item => {
          setTimeout(() => {
            window.open(downloadLink(item.link));
          });
        });
      },
    };
  }

  return {
    name: title,
    icon: `download`,
    apiLink: downloadLink(toShare),
  };
};

export const getShareLinks = (
  title: string,
  toShare: string | TBookmarkInfo[],
  body: (text: string) => string
): TShareLinks => {
  return {
    [EShareType.FACEBOOK]: facebook(title, toShare),
    [EShareType.X]: twitter(title, toShare),
    [EShareType.WHATSAPP]: whatsapp(title, toShare),
    [EShareType.MAIL]: mail(title, toShare, body),
    [EShareType.DOWNLOAD]: download(title, toShare),
  };
};

export const useShareLinks = (
  title: string,
  toShare: string | TBookmarkInfo[],
  whiteList: Array<EShareType> = [
    EShareType.FACEBOOK,
    EShareType.X,
    EShareType.WHATSAPP,
    EShareType.MAIL,
  ]
): TShareLinks => {
  const [t] = useTranslation('common');

  const urls = getShareLinks(title, toShare, text =>
    t('share.wishlist.mail', { text })
  );
  return pick(whiteList, urls);
};
