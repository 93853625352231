import { useTranslation } from 'next-i18next';
import React from 'react';
import { INormalizedImage } from '@hotelplan/components.common.image';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { useToggleState } from '@hotelplan/libs.hooks-react';
import { useMetaDataContext } from '@hotelplan/libs.meta-data';
import ShareOverlay from 'components/domain/share-overlay/share-overlay';
import { EShareType } from 'components/domain/share-overlay/useShareLinks';
import { tryMobileShare } from 'components/domain/sitetools/share-site-tool.utils';
import ShareIcon from 'components/domain/sitetools/ShareIcon';
import { SiteToolsButtonsWrapper } from './SiteTools.common';

const DEFAULT_CLASS_NAME = 'site-tools-button-wrapper';

export interface IShareSiteToolProps {
  pageTitle: string;
  image: INormalizedImage | null;
  onClose?(): void;
  show?: boolean;
  url: string;
  icon?: string;
  iconText?: string;
  overlayTitle?: string;
  whiteList?: Array<EShareType>;
  pdf?: boolean;
  trackFns?: {
    trackOpen(): void;
    trackShare(type: string): void;
  };
  className?: string;
}

const ShareSiteTool: React.FC<IShareSiteToolProps> = props => {
  const [t] = useTranslation('common');

  const {
    url,
    pageTitle,
    image,
    icon,
    overlayTitle = t('common:share.overlay.title'),
    iconText = t('common:page.share'),
    whiteList,
    pdf = false,
    trackFns,
    className = DEFAULT_CLASS_NAME,
  } = props;
  const { trackOpen, trackShare } = trackFns || {
    trackOpen: () => void 0,
    trackShare: () => void 0,
  };

  const [isOpened, openModal, closeModal] = useToggleState(false);

  const { mobile } = useDeviceType();
  const meta = useMetaDataContext();

  function handleShareSiteToolClick() {
    trackOpen();

    if (tryMobileShare(meta, mobile, url, !pdf)) {
      openModal();
    }
  }

  return (
    <>
      <SiteToolsButtonsWrapper
        onClick={handleShareSiteToolClick}
        className={className}
      >
        <ShareIcon icon={icon} />
        <span className="button-text">{iconText}</span>
      </SiteToolsButtonsWrapper>
      {isOpened && (
        <ShareOverlay
          overlayTitle={overlayTitle}
          pageTitle={pageTitle}
          image={image}
          onClose={closeModal}
          toShare={url}
          whiteList={whiteList}
          trackFn={trackShare}
          pdf={pdf}
        />
      )}
    </>
  );
};

export default ShareSiteTool;
