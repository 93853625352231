import React from 'react';
import closeFullscreenIcon from 'public/images/svg/close-fullscreen.svg';
import openFullscreenIcon from 'public/images/svg/open-fullscreen.svg';
import resetMapIcon from 'public/images/svg/reset-map.svg';

interface IFullScreenControl {
  onChange: React.Dispatch<React.SetStateAction<boolean>>;
}

const defaultIconStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '40px',
  height: '40px',
  border: '2px solid #fff',
  borderRadius: '3px',
  background: 'white',
  boxShadow: '0 2px 6px rgba(0,0,0,.3)',
  cursor: 'pointer',
  padding: '6px',
};

const fullScreenIconStyles = { ...defaultIconStyles, margin: '10px' };
const resetMapIconStyles = {
  ...defaultIconStyles,
  marginRight: '10px',
};

export const createFullScreenControl = ({
  onChange,
}: IFullScreenControl) => (): HTMLDivElement | undefined => {
  if (process.browser) {
    const fullScreenControl = document.createElement('div');
    const controlUI = document.createElement('button');
    const controlIcon = document.createElement('img');

    Object.entries(fullScreenIconStyles).forEach(([prop, value]) => {
      controlUI.style[prop] = value;
    });

    controlIcon.src = openFullscreenIcon;

    controlUI.addEventListener('click', () => {
      onChange(prev => {
        controlIcon.src = !prev ? closeFullscreenIcon : openFullscreenIcon;
        return !prev;
      });
    });

    controlUI.appendChild(controlIcon);
    fullScreenControl.appendChild(controlUI);

    return fullScreenControl;
  }
};

interface IResetMapControl {
  onClick: () => void;
}

export const createResetMapControl = ({ onClick }: IResetMapControl) => ():
  | HTMLDivElement
  | undefined => {
  if (process.browser) {
    const resetMapControl = document.createElement('div');
    const controlUI = document.createElement('button');
    const controlIcon = document.createElement('img');

    Object.entries(resetMapIconStyles).forEach(([prop, value]) => {
      controlUI.style[prop] = value;
    });

    controlIcon.src = resetMapIcon;

    controlUI.addEventListener('click', () => {
      onClick && onClick();
    });

    controlUI.appendChild(controlIcon);
    resetMapControl.appendChild(controlUI);

    return resetMapControl;
  }
};
